import Api from '@/api/api'
import { createBlobFromArrayBuffer } from '@/helpers'

class FileApi extends Api {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.VUE_APP_FILE_API
  }

  async getFilesByFolderName(folderName) {
    const { data } = await this.client.get(
      `/v1/file/folder/${folderName}/files`
    )
    return data
  }

  async getFileByFilename(filename, mimeType) {
    const { data } = await this.client.get(`/v1/file/${filename}`, {
      responseType: 'arraybuffer',
    })
    return createBlobFromArrayBuffer(data, mimeType)
  }

  async saveFile(file, category, mimeType, fileTagId) {
    if (!file || !category) {
      return false
    }
    const { data } = await this.client.post(`/v1/file/save/${category}`, file, {
      params: {
        mimeType,
        fileTagId,
      },
    })
    return data
  }
}

export default FileApi
